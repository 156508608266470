/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
    Button, Row, Col, Spinner
} from 'reactstrap';
import { Box, Typography } from 'lib';
import { ContainerStyled, FormWrapper } from 'common';
import { LocationAlert } from 'components/Modals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { API } from 'aws-amplify';
import { useParams } from 'react-router-dom';
import get from 'lodash.get';
import * as queries from '../graphql/queries';

function Settings(props) {
    const [state, setState] = useState({ isLoading: false });
    const [isOpen, setIsOpen] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        async function getItem() {
            try {
                const address = await API.graphql({
                    query: queries.listSuapmiLocations
                });
                const addr = get(address, 'data.listSuapmiLocations.items', []);
                if (Array.isArray(addr) && addr.length > 0) {
                    setState((prevState) => ({
                        ...prevState,
                        address  : addr[0],
                        isLoading: false
                    }));
                }
            } catch (err) {
                if (err && err.data && !err.data.getItem) {
                    setState((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        item     : null,
                        error    : 'not-found'
                    }));
                }
            }
        }

        getItem();
    }, [id]);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    function onComplete(userAddress) {
        setState((prevState) => ({
            ...prevState,
            address: userAddress
        }));
        toggleModal();
    }

    if (state.isLoading) {
        return (
            <ContainerStyled className="wrapper">
                <Row className="pt-3">
                    <Col>
                        <FormWrapper className="mb-5">
                            <Spinner size="sm" /> Please wait...
                        </FormWrapper>
                    </Col>
                </Row>
            </ContainerStyled>
        );
    }

    return (
        <ContainerStyled className="wrapper">
            <Row>
                <Col>
                    <Box
                        display="flex"
                        alignItems="baseline"
                        marginBottom="15px"
                        paddingTop="2px"
                    >
                        <Button outline color="secondary" onClick={() => window.history.back()}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                    </Box>
                    <Typography className="mb-0" fontWeight="bold">
                        Settings
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        background="#fff"
                        className="p-3 mb-3"
                        border="1px solid #eee"
                        cursor="pointer"
                    >
                        {state.address ? `${state.address.city}, ${state.address.state}` : 'No ciudad especificada'}
                        <Button onClick={toggleModal} color="link">
                            {state.address ? 'Editar' : 'Agregar'}
                        </Button>
                    </Box>
                </Col>
            </Row>
            {isOpen && (
                <LocationAlert
                    update={Boolean(state.address)}
                    data={state.address}
                    modalOpen={isOpen}
                    onComplete={onComplete}
                    toggle={toggleModal}
                />
            )}
        </ContainerStyled>
    );
}

export default Settings;
