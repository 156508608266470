import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

function Loading(props) {
    const { size, text } = props;
    return (
        <>
            <Spinner size={size} />{' '} {text}
        </>
    );
}

Loading.propTypes = {
    size: PropTypes.string,
    text: PropTypes.string
};

Loading.defaultProps = {
    size: 'sm',
    text: 'Loading...'
};

export default Loading;
