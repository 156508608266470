/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { isMobile, isBrowser } from 'react-device-detect';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser, faCog, faHeart, faCamera, faWarehouse
} from '@fortawesome/free-solid-svg-icons';
import { APP_LOGO_URL, TAG_LINE } from 'app-exports';

import get from 'lodash.get';
import { Box, Typography } from 'lib';
import { AppContext } from 'appContext';

const Logo = styled.img`
    height: 50px;
`;

const TagLine = styled.div`
    font-size: 12px;
    margin-left: 10px
`;

const NavbarStyled = styled(Navbar)`
    border-bottom: 1px solid #eee;
    position: fixed;
    width: 100%;
    z-index: 999;
`;

const DropdownItemStyled = styled(DropdownItem)`
    :active {
        background-color: #f7f7f7;
    }
`;

function Header(props) {
    const [state, setState] = useState({ isOpen: false });
    const [appState, dispatch] = useContext(AppContext);
    const { user } = appState;
    const isSuperAdmin = get(user, 'claims.superAdmin');

    async function logout() {
        const {
            history: { push }
        } = props;
        try {
            await Auth.signOut();
            dispatch({ type: 'USER_LOGOUT_SUCCESS', data: null });
            push('/');
            window.location.reload();
        } catch (error) {
            dispatch({ type: 'USER_LOGOUT_ERROR', data: 'Unable to log you out' });
            push('/');
        }
    }

    function toggle(e) {
        if (isMobile || isBrowser) {
            const { isOpen } = state;
            setState({
                isOpen: !isOpen
            });

            if (e.target.name === 'login' || e.target.name === 'register') {
                dispatch({ type: 'AUTHENTICATION_STATE', data: e.target.name });
            }
        }
    }

    const { isOpen } = state;

    return (
        <NavbarStyled color="white" expand="md" light>
            <NavbarBrand href="/">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Logo src={APP_LOGO_URL} />
                    {TAG_LINE && <TagLine>{TAG_LINE}</TagLine>}
                </div>
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    {user ? (
                        <>
                            <NavItem>
                                <Link
                                    className="nav-link"
                                    to="/item/create"
                                    onClick={toggle}
                                >
                                    <FontAwesomeIcon icon={faCamera} /> Crear Anuncio
                                </Link>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    {user.username}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItemStyled>
                                        <Link to="/admin/account" onClick={toggle}>
                                            <Box display="flex" alignItems="baseline">
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                    className="mr-2"
                                                />
                                                <Typography marginLeft="10px">Mi Cuenta</Typography>
                                            </Box>
                                        </Link>
                                    </DropdownItemStyled>
                                    <DropdownItem>
                                        <Link to="/admin/inventory" onClick={toggle}>
                                            <Box display="flex" alignItems="baseline">
                                                <FontAwesomeIcon
                                                    icon={faWarehouse}
                                                    className="mr-2"
                                                />
                                                <Typography marginLeft="10px">Vendiendo</Typography>
                                            </Box>
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItemStyled>
                                        <Link to="/admin/favorites" onClick={toggle}>
                                            <Box display="flex" alignItems="baseline">
                                                <FontAwesomeIcon
                                                    icon={faHeart}
                                                    className="mr-2"
                                                />
                                                <Typography marginLeft="10px">Favoritos</Typography>
                                            </Box>
                                        </Link>
                                    </DropdownItemStyled>
                                    {isSuperAdmin && (
                                        <DropdownItemStyled>
                                            <Link to="/admin/businesses" onClick={toggle}>
                                                <Box display="flex" alignItems="baseline">
                                                    <FontAwesomeIcon
                                                        icon={faCog}
                                                    />
                                                    <Typography marginLeft="10px">Administration</Typography>
                                                </Box>
                                            </Link>
                                        </DropdownItemStyled>
                                    )}
                                    <DropdownItem divider />
                                    <DropdownItemStyled onClick={logout}>
                                        Logout
                                    </DropdownItemStyled>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </>
                    ) : (
                        <>
                            <NavItem>
                                <Link name="login" className="nav-link" to="/admin/account" onClick={toggle}>
                                    Login
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link name="register" className="nav-link" to="/admin/account" onClick={toggle}>
                                    Sign up
                                </Link>
                            </NavItem>
                        </>
                    )}
                </Nav>
            </Collapse>
        </NavbarStyled>
    );
}

export default withRouter(Header);
