import styled from 'styled-components';

const Pointer = styled.div`
    width: 40px;
    height: 20px;
    position: relative;
    background: #FF7500;
    color: white;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;

    :after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
    }

    :before {
        content: "";
        position: absolute;
        right: -16px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 16px solid #FF7500;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
`;

export default Pointer;
