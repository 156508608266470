import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import SearchBox from './SearchBox';

const HomeSearchWrapper = styled.div`
    display: flex;
    position: relative;
    background: no-repeat url('https://343trucking.s3-us-west-2.amazonaws.com/img-port-background.jpg');
    background-size: cover;
    background-position-y: -200px;
    height: 250px;
    align-items: center;

    /* Small devices (landscape phones, 576px and lower) */
    @media (max-width: 576px) {
        background: #21201d;
        background-position-y: 0;
        height: 150px;
    }

    @media (min-width: 576px) {
        background-position-y: 0;
    }
`;

const Jumbotron = styled.h1`
    font-size: 3rem;
    font-weight: 700;
    font-family: sans-serif;
    color: #f1f1f1;
    margin-bottom: 20px;
`;

const Overlay = styled.div`
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.55);
    color: black;
`;

const Wrapper = styled.div`
    text-align: center;
`;

function HomeSearch(props) {
    return (
        <HomeSearchWrapper id="search-container">
            <Overlay className="overlay" />
            <Container>
                <Row>
                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                        <Wrapper>
                            <Jumbotron id="jumbotron">
                                Clasificados
                            </Jumbotron>
                            <SearchBox {...props} />
                        </Wrapper>
                    </Col>
                </Row>
            </Container>
        </HomeSearchWrapper>

    );
}

export default HomeSearch;
