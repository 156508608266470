/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import { SearchProvider, SearchBox } from '@elastic/react-search-ui';
import { elasticSearch } from 'app-exports';

import '@elastic/react-search-ui-views/lib/styles/styles.css';

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
`;

const connector = new AppSearchAPIConnector({
    searchKey     : elasticSearch.searchKey,
    engineName    : elasticSearch.engineName,
    endpointBase  : elasticSearch.endpointBase,
    cacheResponses: false
});

function SearchBoxComp(props) {
    const { handlers } = props;

    return (
        <SearchWrapper id="search-wrapper">
            <InputWrapper>
                <SearchProvider
                    className="SearchProvider---class"
                    config={{
                        apiConnector: connector
                    }}
                >
                    <SearchBox
                        autocompleteSuggestions
                        innerClass="yoyoyoy-class"
                        className="yayayayay-here"
                        onSubmit={(searchTerm) => handlers.handleSearch(`q=${searchTerm}`)}
                        // eslint-disable-next-line no-empty-pattern
                        onSelectAutocomplete={(selection, {}, defaultOnSelectAutocomplete) => {
                            if (selection.suggestion) {
                                handlers.handleSearch(`q=${selection.suggestion}`);
                            } else {
                                defaultOnSelectAutocomplete(selection);
                            }
                        }}
                    />
                </SearchProvider>
            </InputWrapper>
        </SearchWrapper>
    );
}

SearchBoxComp.propTypes = {
    handlers: PropTypes.object
};

SearchBoxComp.defaultProps = {
    handlers: {}
};

export default SearchBoxComp;
