/* eslint-disable no-param-reassign */
/* eslint-disable no-debugger */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const BoxStyled = styled.div`
    display: flex;
    ${({
        alignItems, justifyContent, background, width, ...rest
    }) => {
        delete rest.children;
        delete rest.className;
        return {
            alignItems,
            justifyContent,
            background,
            width,
            ...rest
        };
    }}
`;

function Box(props) {
    const { children, className, ...rest } = props;

    return (
        <BoxStyled className={className} {...rest}>
            {children}
        </BoxStyled>
    );
}

Box.propTypes = {
    alignItems    : PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    children      : PropTypes.any,
    justifyContent: PropTypes.oneOf(['start', 'space-between']),
    background    : PropTypes.string,
    className     : PropTypes.string
};

Box.defaultProps = {
    alignItems    : null,
    children      : null,
    justifyContent: null,
    background    : null,
    className     : null
};

export default Box;
