/* eslint-disable no-alert */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import { GOOGLE_MAP_ACCESS_KEY, S3_BUCKET, CLOUDFRONT_DISTRIBUTION } from 'app-exports';
import { Loader } from '@googlemaps/js-api-loader';
import get from 'lodash.get';

function formatCurrensy(price) {
    if (!price) return null;

    const parsedPrice = parseInt(price, 10);

    const formmatedPrice = parsedPrice.toFixed(price % 1 === 0 ? 0 : 2);

    return `$${formmatedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

function callFunc(callback, ...args) {
    return typeof callback === 'function' ? callback(...args) : null;
}

/**
 * Parse addresses
 *
 * @param {String} address It can be a city or full address
 * @param {Object} geoPoints 2 points: { lat: float, lng: float }
 * @returns A geo point or address object
 */
async function getGeoloc(address, geoPoints) {
    let geocoder;
    try {
        const loader = new Loader({
            apiKey : GOOGLE_MAP_ACCESS_KEY,
            version: 'weekly'
        });
        await loader.load();
        // eslint-disable-next-line no-undef
        geocoder = new google.maps.Geocoder();

        let result;
        if (geoPoints) {
            try {
                await geocoder.geocode({ location: geoPoints }, (response) => {
                    // eslint-disable-next-line no-undef
                    if (response && response[0]) {
                        // eslint-disable-next-line prefer-destructuring
                        result = response[0];
                    }
                });
                return result;
            } catch (err) {
                return 'error-decoding-geopoints';
            }
        }

        // eslint-disable-next-line consistent-return
        await geocoder.geocode({ address }, (results, status) => {
            // eslint-disable-next-line no-undef
            if (status === google.maps.GeocoderStatus.OK) {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                result = { lat, lng };
            }
        });

        return result;
    } catch (error) {
        throw new Error(JSON.stringify({
            message: 'Error loading or parsing the address to geoloc',
            error
        }));
    }
}

function getCategoryLabel(categoryKey, categories) {
    return categories.find((c) => c.value === categoryKey);
}

function getSubcategoryLabel(category, subCategory, subCategories) {
    const temp = subCategories[category];
    if (!Array.isArray(temp)) { return ''; }
    return temp.find((s) => s.value === subCategory);
}

function transformESResult(item) {
    return {
        id      : get(item, 'id.raw', ''),
        title   : get(item, 'title.raw', ''),
        price   : get(item, 'price.raw', ''),
        image   : get(item, 'images.raw', ''),
        location: get(item, 'location.raw', ''),
        owner   : get(item, 'owner.raw', '')
    };
}

function askForUserLocation() {
    if ('geolocation' in navigator) {
        const pos = navigator.geolocation.getCurrentPosition(
            (position) => position.coords,
            () => window.alert('Sorry, no position')
        );
        return pos;
    }
    throw new Error('Your browser does not support this feature');
}

function renderImageFromCloudFront(imgKey, width = 75) {
    const imageRequest = JSON.stringify({
        bucket: S3_BUCKET,
        key   : `public/${imgKey}`,
        edits : {
            resize: { width, fit: 'cover' }
        }
    });
    return `${CLOUDFRONT_DISTRIBUTION}/${btoa(imageRequest)}`;
}

function formatItemForElasticSearch(itemObject) {
    // find the main image
    const image = Array.isArray(itemObject.images) && itemObject.images.find((img) => img.includes('main'));

    return {
        id         : itemObject.id,
        owner      : itemObject.owner,
        title      : itemObject.title,
        price      : itemObject.price,
        description: itemObject.description,
        location   : itemObject.location,
        geolocation: itemObject.geolocation,
        category   : itemObject.category,
        subCategory: itemObject.subCategory,
        postedDate : itemObject.postedDate,
        status     : itemObject.status,
        images     : image || itemObject.images[0]
    };
}

function parseAddress(addressResponse, coords) {
    const address = {
        street     : '',
        geolocation: `${coords.latitude}, ${coords.longitude}`
    };
    if (addressResponse && addressResponse.address_components) {
        addressResponse.address_components.forEach((comp) => {
            if (comp.types.indexOf('postal_code') !== -1) {
                address.zipcode = comp.long_name;
            }
            if (comp.types.indexOf('locality') !== -1) {
                address.city = comp.long_name;
            }
            if (comp.types.indexOf('administrative_area_level_1') !== -1) {
                address.state = comp.short_name;
            }
        });
    }
    return address;
}

// get user location
async function getUserLocation(API, queries) {
    try {
        const res = await API.graphql({
            query: queries.listSuapmiLocations
        });
        const result = get(res, 'data.listSuapmiLocations.items', []);
        return Promise.resolve(result);
    } catch (err) {
        return null;
    }
}

export {
    getGeoloc,
    getCategoryLabel,
    getSubcategoryLabel,
    transformESResult,
    formatCurrensy,
    askForUserLocation,
    callFunc,
    renderImageFromCloudFront,
    formatItemForElasticSearch,
    parseAddress,
    getUserLocation
};
