/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import {
    Alert, Container, Row, Col, Button, Badge
} from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser, faPhoneAlt, faEnvelope, faArrowRight, faDollarSign, faHeart, faCog
} from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from 'lib';
import { AppContext } from 'appContext';

const ProfilePicture = styled.div`
    display: flex;
    font-size: 36px;
    img {
        width: 75px;
    }
`;

const ContainerStyled = styled(Container)`
    width: 500px;
    @media (max-width: 415px) {
        &.wrapper {
            width: 100% !important;
        }
    }
`;

const iconStyles = {
    fontSize  : '28px',
    color     : '#676767',
    marginLeft: '12px'
};

function Account(props) {
    // get the current user from local storage
    const appContext = useContext(AppContext);
    const [appState] = appContext;
    const { user } = appState;

    function sendVericationEmail() {
        // FIXME: replace with AWS Cognito
    }

    function navigate(url) {
        props.history.push(url);
    }

    return (
        <ContainerStyled className="wrapper">
            <Row>
                <Col>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        background="#fff"
                        border="1px solid #eee"
                        className="pt-3 pb-2 mb-3 mt-3"
                    >
                        <ProfilePicture>
                            {(user && user.photoURL)
                                ? <img src={user.photoURL} alt="" className="rounded-circle" />
                                : <FontAwesomeIcon icon={faUser} styles={iconStyles} />}
                        </ProfilePicture>
                        <Typography
                            color="#5a5a5a"
                            type="span"
                            textTransform="uppercase"
                            fontWeight="bold"
                            display="flex"
                            alignItems="baseline"
                            marginBottom="0"
                            className="mt-2 mb-0"
                        >
                            {user && user.username}
                        </Typography>
                        <Typography marginBottom="0">
                            <FontAwesomeIcon icon={faEnvelope} />{' '}
                            {user && user.email}
                            <Badge className="ml-1" color={(user && user.email_verified) ? 'success' : 'warning'}>
                                {(user && user.email_verified) ? 'Verificado' : 'No verificado'}
                            </Badge>
                        </Typography>
                        {user && user.phone_number && (
                            <Typography>
                                <FontAwesomeIcon icon={faPhoneAlt} />{' '}
                                {user.phone_number}
                            </Typography>
                        )}
                        {(user && !user.email_verified) && (
                            <Alert color="warning" className="mt-1">
                                <Typography className="mb-1">
                                    Por favor verifique su dirección de correo electrónico.
                                </Typography>
                                <Button
                                    block
                                    outline
                                    color="secondary"
                                    onClick={sendVericationEmail}
                                >
                                    Enviar código
                                </Button>
                            </Alert>
                        )}
                        <Typography marginBottom="0">
                            {user && user.location}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        background="#fff"
                        className="p-3 mb-3"
                        border="1px solid #eee"
                        cursor="pointer"
                        onClick={() => navigate('/admin/inventory')}
                    >
                        <Typography marginBottom="0" fontWeight="bold">
                            <FontAwesomeIcon icon={faDollarSign} />
                            {' '}
                            Vendiendo
                        </Typography>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        background="#fff"
                        className="p-3 mb-3"
                        border="1px solid #eee"
                        cursor="pointer"
                        onClick={() => navigate('/admin/favorites')}
                    >
                        <Typography marginBottom="0" fontWeight="bold">
                            <FontAwesomeIcon icon={faHeart} />
                            {' '}
                            Favoritos
                        </Typography>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        background="#fff"
                        className="p-3 mb-3"
                        border="1px solid #eee"
                        cursor="pointer"
                        onClick={() => navigate('/admin/settings')}
                    >
                        <Typography marginBottom="0" fontWeight="bold">
                            <FontAwesomeIcon icon={faCog} />
                            {' '}
                            Settings
                        </Typography>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Box>
                </Col>
            </Row>
        </ContainerStyled>
    );
}

export default Account;
