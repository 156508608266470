/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
    Button, Container, Row, Col
} from 'reactstrap';
import { Box, Divider, Typography } from 'lib';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCamera, faArrowLeft, faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { formatCurrensy, renderImageFromCloudFront } from 'utils';
import { Link } from 'react-router-dom';
import get from 'lodash.get';
import styled from 'styled-components';
import { API } from 'aws-amplify';
import { NO_IMG_URL, STATUS } from 'app-exports';
import { Image } from 'common';
import * as queries from '../../../graphql/queries';

const ContainerStyled = styled(Container)`
    @media (max-width: 415px) {
        &.wrapper {
            width: 100% !important;
        }
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

function removeItem() {
    return Promise.reject(new Error('No implemented'));
}

function Inventory(props) {
    const [state, setState] = useState({
        alertIsOpen : false,
        isLoading   : false,
        items       : [],
        selectedItem: {},
        totalRecords: 0
    });

    useEffect(() => {
        async function getItems() {
            try {
                const response = await API.graphql({
                    query    : queries.listItems,
                    variables: {
                        limit : 100,
                        filter: {
                            status: { eq: STATUS.ACTIVE }
                        }
                    }
                });
                const items = get(response, 'data.listItems.items', []);
                setState(() => ({
                    // TODO: use the sort provided by graphql
                    items       : items.sort((a, b) => b.postedDate - a.postedDate),
                    totalRecords: items.length,
                    isLoading   : false
                }));
            } catch (err) {
                setState((prevState) => ({ ...prevState, isLoading: false }));
            }
        }

        getItems();
    }, []);

    function handleAlertToggle(row) {
        const { alertIsOpen } = state;
        setState((prevState) => ({
            ...prevState,
            alertIsOpen : !alertIsOpen,
            selectedItem: row
        }));
    }

    async function handleOnDelete(item) {
        try {
            // F
            const resp = await removeItem(item.id);
            // display success message
            toast.success(resp.message);
            // close the modal
            handleAlertToggle();
        } catch (error) {
            // close the modal
            handleAlertToggle();
            // display error message
            toast.error(
                'Unable to process your process. Please try again. If problem persist, please contact support.'
            );
        }
    }

    function navigate(url) {
        // eslint-disable-next-line react/prop-types
        props.history.push(url);
    }

    function renderImage(images) {
        const imgKey = Array.isArray(images) && images[0];
        return imgKey
            ? <Image src={renderImageFromCloudFront(imgKey)} />
            : <Image src={NO_IMG_URL} width="100%" />;
    }

    return (
        <ContainerStyled id="selling-container" className="wrapper">
            <Row>
                <Col>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                        paddingTop="10px"
                    >
                        <Typography type="h5" margin="0">
                            <Button outline color="secondary" onClick={() => window.history.back()}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Button>
                        </Typography>
                        <Link
                            to="/item/create"
                            className="btn btn-outline-secondary"
                            style={{
                                height  : '35px',
                                fontSize: '14px'
                            }}
                        >
                            <FontAwesomeIcon icon={faCamera} /> Nuevo
                        </Link>
                    </Box>
                    <Divider />
                    <h5>Vendiendo</h5>
                    {/* NOTE: we have to filter by _deleted because AppSync keeps the item for 30 days. We can't hard delete it */}
                    {(Array.isArray(state.items) && state.items.length > 0) ? state.items.filter((f) => f._deleted !== true).map((item) => (
                        <Box
                            key={item.id}
                            background="#fff"
                            marginTop="10px"
                            padding="5px"
                            cursor="pointer"
                            onClick={() => navigate(`/admin/item-details/${item.id}`)}
                        >
                            <div style={{ display: 'flex', flex: '0 0 100%' }}>
                                <div style={{ width: '75px' }}>
                                    {renderImage(item.images)}
                                </div>
                                <div className="ml-2" style={{ width: '100%' }}>
                                    <Typography fontWeight="500" marginBottom="5px">
                                        {item.title}
                                    </Typography>
                                    <Typography marginBottom="0" className="mb-1">
                                        {formatCurrensy(item.price)}
                                    </Typography>
                                    <Typography
                                        marginBottom="5px"
                                        fontSize="12px"
                                        textTransform="capitalize"
                                    >
                                        {item.location}
                                    </Typography>
                                </div>
                                <ActionsWrapper>
                                    <Link
                                        to={`/admin/item-details/${item.id}`}
                                        className="text-dark pl-1 pr-1"
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </Link>
                                </ActionsWrapper>
                            </div>
                        </Box>
                    )) : (
                        <Box background="#fff" marginTop="10px" padding="10px">
                            No hay nada
                        </Box>
                    )}
                </Col>
            </Row>
        </ContainerStyled>
    );
}

export default Inventory;
