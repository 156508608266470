/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unreachable */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Typography } from 'lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Spinner } from 'reactstrap';
import { Storage } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { renderImageFromCloudFront } from 'utils';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
    padding: 10px;
    text-align: left;
`;

const AddImage = styled.div`
    height: 125px;
    width: 125px;
    border: 1px dotted #7a7a7a;
    padding: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 36px;
    cursor: pointer;
`;

const FileInput = styled.input`
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
    overflow: hidden;
    z-index: -1;
`;

const ImagesPreviewWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    width: 125px;
    height: 125px;
    margin-bottom: 15px;
`;

const ImagePreview = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const Circle = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: red;
    color: white;
    top: -12px;
    right: -10px;
    cursor: pointer;
`;

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid #eee;
    margin: 10px 0 0;
`;

const NUM_IMAGES_ALLOWED = 6;

function ImageUploader(props) {
    const { images, handlers, action } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [useCamera, setUseCamera] = useState(false);
    const [files, setFiles] = useState(images.map((key) => ({
        key,
        status: 'UPLOADED'
    })));

    const inputRef = useRef();

    useEffect(() => {
        function cleanup() {
            if (files.length > 0) {
                files.forEach((file) => {
                    URL.revokeObjectURL(file);
                });
            }
        }

        return cleanup();
    }, [files]);

    function onChange(event) {
        if (event.target.files && event.target.files[0]) {
            const file = {
                status: 'PREVIEW',
                file  : event.target.files[0]
            };
            setFiles([...files, file]);
            // eslint-disable-next-line no-param-reassign
            event.target.value = null;
        }
    }

    function openFileExplorer() {
        inputRef.current.click();
    }

    async function removeImageFromS3Bucket(key) {
        try {
            await Storage.remove(key);
            // find index
            const idx = files.findIndex((f) => f.key === key);
            const idx2 = images.indexOf(key);
            // remove
            if (idx !== -1) {
                files.splice(idx, 1);
            }
            // update files list
            setFiles([...files]);
            // notify parent
            if (idx2 !== -1) {
                images.splice(idx2, 1);
            }
            handlers.handleSetImageKeys(
                files.filter((f) => f.status !== 'PREVIEW').map((i) => i.key)
            );
        } catch (err) {
            console.log('Unabel to delete image');
        }
    }

    function removeImage(imgObject, index) {
        // when we are deleting images already uploaded
        if (imgObject.status === 'UPLOADED') {
            // let's remove them from the storage
            removeImageFromS3Bucket(imgObject.key);

            handlers.hasImageChanges(true);
        } else {
            files.splice(index, 1);
            setFiles([...files]);
        }
    }

    async function uploadImage() {
        try {
            const toUploadImages = files.filter((file) => file.status === 'PREVIEW');

            // exit if we don't have any
            if (toUploadImages.length === 0) {
                handlers.setShowDetails(true);
                return;
            }

            setIsLoading(true);

            // list of keys
            const imgs = [];
            // loop over the files object
            const promises = toUploadImages.map(async (img, index) => {
                const { file } = img;
                const type = file.type.split('/');
                let filename = `${uuid()}.${type[1]}`;

                if (index === 0 && action === 'create') {
                    filename = `main.${filename}`;
                }

                const result = await Storage.put(filename, file);

                imgs.push(result.key);
            });

            await Promise.all(promises);

            // build keys
            const newimages = imgs.map((key) => ({
                key,
                status: 'UPLOADED'
            }));
            // build current keys
            const currentImgs = images.map((key) => ({
                key,
                status: 'UPLOADED'
            }));

            // reset internal components
            setFiles([...currentImgs, ...newimages]);
            // notify parent component
            handlers.handleSetImageKeys([...images, ...imgs]);

            setIsLoading(false);

            handlers.setShowDetails(true);
        } catch (err) {
            // if we have an error and we have images,
            // we should try to delete them as well
            setIsLoading(false);
        }
    }

    function getFileInputProps() {
        if (isMobile && useCamera) {
            return {
                capture: 'environment'
            };
        }
        return {};
    }

    function onInputChange() {
        setUseCamera(!useCamera);
    }

    return (
        <Wrapper className="image-wrapper">
            <Box display="flex" justifyContent="space-between" className="mb-4">
                <strong>1. Fotos</strong>
                {isMobile && (
                    <label>
                        <input className="mr-2" type="checkbox" onChange={onInputChange} />
                        Tomar Foto con Camara
                    </label>
                )}
            </Box>
            <ImagesPreviewWrapper>
                {files.map((img, i) => (
                    <ImageWrapper key={i}>
                        <Circle onClick={() => removeImage(img, i)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Circle>

                        {img.status === 'UPLOADED'
                            ? (
                                <ImagePreview src={renderImageFromCloudFront(img.key, 150)} />
                            ) : <ImagePreview src={URL.createObjectURL(img.file)} />}
                    </ImageWrapper>
                )) }
                {files.length < NUM_IMAGES_ALLOWED
                && (
                    <AddImage onClick={openFileExplorer}>
                        <FontAwesomeIcon icon={faPlus} />
                    </AddImage>
                )}
            </ImagesPreviewWrapper>
            <FileInput
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={onChange}
                ref={inputRef}
                {...getFileInputProps()}
            />
            <BtnWrapper>
                <Button
                    color="primary"
                    className="mt-3"
                    onClick={uploadImage}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <>
                            <Spinner size="sm" /> Un momento...
                        </>
                    ) : <>Detalles <FontAwesomeIcon icon={faArrowRight} /></>}
                </Button>
            </BtnWrapper>
            <Typography fontSize="11px" color="#6c6c6c" margin="5px 0 0" textAlign="right">
                * No olvide guardar los detalles para almacenar las imágenes
            </Typography>
        </Wrapper>
    );
}

ImageUploader.propTypes = {
    images  : PropTypes.arrayOf(String),
    action  : PropTypes.string,
    handlers: PropTypes.shape({
        handleSetImageKeys: PropTypes.func,
        setShowDetails    : PropTypes.func,
        hasImageChanges   : PropTypes.func
    })
};

ImageUploader.defaultProps = {
    images  : [],
    action  : '',
    handlers: {
        handleSetImageKeys: () => {},
        setShowDetails    : () => {},
        hasImageChanges   : () => {}
    }
};

export default ImageUploader;
