import PropTypes from 'prop-types';
import React from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import get from 'lodash.get';
import { callFunc } from 'utils/helpers';

function Alert(props) {
    const {
        modalOpen, toggle, text, data, isSubmitting
    } = props;
    const color = get(props, 'buttons.confirm.color', 'primary');
    const label = get(props, 'buttons.confirm.label', 'OK');
    const onClickHandler = get(props, 'buttons.confirm.onClickHandler', toggle);

    function onSubmit() {
        callFunc(onClickHandler, data);
        toggle();
    }

    return (
        <div>
            <Modal isOpen={modalOpen} toggle={toggle} backdrop="static">
                <ModalHeader toggle={toggle}>
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-warning"
                    />{' '}
                    Alert
                </ModalHeader>
                <ModalBody>{text}</ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={toggle} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button color={color} onClick={onSubmit} disabled={isSubmitting}>
                        {isSubmitting && <Spinner size="sm" />}
                        {' '}{label}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

Alert.propTypes = {
    modalOpen   : PropTypes.bool,
    toggle      : PropTypes.func,
    text        : PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    data        : PropTypes.object,
    isSubmitting: PropTypes.bool
};

Alert.defaultProps = {
    modalOpen   : false,
    toggle      : null,
    text        : '',
    data        : {},
    isSubmitting: false
};

export default Alert;
