/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import Settings from 'pages/Settings';
import React, {
    useState, useEffect, useContext
} from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AppContext } from 'appContext';
import Account from './Account';
import Favorites from './Favorites';
import Inventory from './Inventory';
import ItemDetails from './ItemDetails';

function Admin(props) {
    const [authState, setAuthState] = useState();
    const appContext = useContext(AppContext);
    const [appState, dispatch] = appContext;

    useEffect(() => onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState);
        if (!appState.user && authData) {
            const { attributes, username } = authData;
            dispatch({
                type: 'GET_APPLICATION_DATA_SUCCESS',
                data: { user: { ...attributes, username } }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    function getAuthState() {
        switch (appState.authState) {
        case 'login':
            return { initialAuthState: AuthState.SignIn };
        case 'register':
            return { initialAuthState: AuthState.SignUp };
        case 'recover':
            return { initialAuthState: AuthState.ForgotPassword };
        default:
            return {};
        }
    }

    if (authState === 'confirmSignUp' && appState.user) {
        // eslint-disable-next-line react/prop-types
        props.history.push(`/verify?user=${appState.user.username}`);
    }

    return authState === AuthState.SignedIn || appState.user ? (
        <Row noGutters>
            <Col>
                <Switch>
                    <Route exact path="/admin/inventory" component={Inventory} />
                    <Route path="/admin/account" component={Account} />
                    <Route path="/admin/favorites" component={Favorites} />
                    <Route path="/admin/settings" component={Settings} />
                    <Route path="/admin/item-details/:id" component={ItemDetails} />
                </Switch>
            </Col>
        </Row>
    ) : (
        <AmplifyAuthenticator {...getAuthState()} />
    );
}

export default Admin;
