/* eslint-disable react/prop-types */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { getGeoloc } from 'utils/helpers';
import * as mutations from 'graphql/mutations';
import { API } from 'aws-amplify';
import { Typography } from 'lib';
import { parseAddress } from 'utils';
import { AppContext } from 'appContext';
import { Loading } from 'components';
import { toast } from 'react-toastify';

function LocationAlert(props) {
    const appContext = useContext(AppContext);
    const [appState, dispatch] = appContext;
    const [submitting, setIsSubmitting] = useState();

    const {
        modalOpen, toggle, data
    } = props;

    async function locationSuccess(position) {
        try {
            const { coords } = position;
            // parse coordinates to get location object
            const resp = await getGeoloc(null, { lat: coords.latitude, lng: coords.longitude });
            // parse address
            const addr = parseAddress(resp, coords);
            // save result
            let result;
            // save address to the database
            if (addr && appState.user) {
                if (data && data.id) {
                    const tempData = data;
                    delete tempData.owner;
                    const updated = await API.graphql({
                        query    : mutations.updateSuapmiLocation,
                        variables: {
                            input: {
                                ...tempData,
                                ...addr
                            }
                        }
                    });
                    result = updated.data.updateSuapmiLocation;
                } else {
                    const created = await API.graphql({
                        query    : mutations.createSuapmiLocation,
                        variables: {
                            input: { ...addr }
                        }
                    });
                    result = created.data.createSuapmiLocation;
                }
                // update app state
                dispatch({ type: 'USER_LOCATION_SUCCESS', data: result });
            } else {
                const userLocation = JSON.stringify(addr);
                dispatch({ type: 'USER_LOCATION_SUCCESS', data: addr });
                window.localStorage.setItem('suapmiUserLocation', userLocation);
            }

            props.onComplete(addr);

            toggle();
        } catch (err) {
            console.log('LocationAlert.locationSuccess().error', err);
            toast.error('Incapaz de determinar su ubicación');
            toggle();
        }
    }

    async function onSubmit() {
        try {
            if ('geolocation' in navigator) {
                setIsSubmitting(true);
                navigator.geolocation.getCurrentPosition(locationSuccess);
            } else {
                throw new Error('No se pudo obtener ubicación.');
            }
        } catch (err) {
            // eslint-disable-next-line no-alert
            window.alert(err);

            setIsSubmitting(true);
        }
    }

    return (
        <div>
            <Modal isOpen={modalOpen} toggle={toggle} backdrop="static">
                <ModalHeader toggle={toggle}>
                    Localizacíon
                </ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: 'center' }}>
                        <Typography fontWeight="bold">¿Dónde quieres buscar?</Typography>
                        <Button onClick={onSubmit} color="success" className="text-white">
                            {!submitting ? (
                                <>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />{' '}
                                    Obtener mi bicación
                                </>
                            ) : (
                                <>
                                    <Loading text="Un momento" />
                                </>
                            )}
                        </Button>
                        {/* <Typography margin="20px 0" textAlign="center">Usar Zipcode</Typography>
                        <Input /> */}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

LocationAlert.propTypes = {
    modalOpen : PropTypes.bool,
    toggle    : PropTypes.func,
    onComplete: PropTypes.func
};

LocationAlert.defaultProps = {
    modalOpen : false,
    toggle    : null,
    onComplete: () => {}
};

export default LocationAlert;
