/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from 'react';
import {
    Container, Row, Col, Button, Badge, Media, Spinner
} from 'reactstrap';
import styled from 'styled-components';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import get from 'lodash.get';
import { Box, Typography } from 'lib';
import LANGUAGES from 'languages.json';
import { AppContext } from 'appContext';
import { Link } from 'react-router-dom';

import { APP_LANG } from 'app-exports';
import { renderImageFromCloudFront } from 'utils';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';

const ContainerStyled = styled(Container)`
    @media (max-width: 415px) {
        &.wrapper {
            width: 100% !important;
        }
    }
`;

function Favorites() {
    const appContext = useContext(AppContext);
    const [appState] = appContext;
    const { user } = appState;
    const [state, setState] = useState({
        totalRecords: 0, favorites: [], isLoading: false, submitting: false
    });

    useEffect(() => {
        async function fetchFavorites() {
            try {
                setState((prevState) => ({ ...prevState, isLoading: true }));
                const resp = await API.graphql({
                    query    : queries.watchlistByDate,
                    variables: {
                        sortDirection: 'DESC',
                        owner        : user.username,
                        createdAt    : {
                            lt: Math.floor(Date.now() / 1000)
                        }
                    }
                });
                const favorites = get(resp, 'data.watchlistByDate.items', []);

                const filteredFavs = favorites.filter((f) => f._deleted !== true);
                setState((prevState) => ({
                    ...prevState,
                    totalRecords: filteredFavs.length,
                    favorites   : filteredFavs,
                    isLoading   : false
                }));
            } catch (err) {
                // eslint-disable-next-line no-alert
                window.alert(LANGUAGES[APP_LANG].ERROR_FETCHING);
                setState((prevState) => ({ ...prevState, isLoading: false }));
            }
        }
        fetchFavorites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleOnDeleteFavorite(id, itemId, version) {
        try {
            setState((prevState) => ({ ...prevState, selected: { itemId }, submitting: true }));
            await API.graphql({
                query    : mutations.deleteWatchlist,
                variables: {
                    input: {
                        id,
                        _version: version
                    }
                }
            });
            const { favorites } = state;
            const copy = favorites.filter((fav) => fav.id !== id);
            setState((prevState) => ({
                ...prevState,
                favorites   : copy,
                totalRecords: copy.length,
                submitting  : false
            }));
        } catch (err) {
            toast.error('Unable to delete');
            setState((prevState) => ({ ...prevState, submitting: false }));
        }
    }

    if (state.isLoading) {
        return <div className="text-center p-4"><Spinner size="sm" color="secondary" /> loading...</div>;
    }

    return (
        <ContainerStyled className="wrapper">
            <Row>
                <Col>
                    <Box display="flex" flexDirection="column" className="mt-2 mb-3">
                        <Typography type="h5" margin="0">
                            <Button outline color="secondary" onClick={() => window.history.back()}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Button>
                        </Typography>
                    </Box>
                    <Typography className="mb-0" fontWeight="bold">
                        Favoritos <Badge>{state.totalRecords}</Badge>
                    </Typography>
                    {state.favorites.length > 0 ? state.favorites.map((favorite) => (
                        <Box key={favorite.id} background="#fff" marginTop="10px" padding="10px">
                            <Media>
                                <Media left href="#">
                                    <Media
                                        object
                                        src={renderImageFromCloudFront(favorite.image)}
                                        alt={favorite.title}
                                        style={{ width: '100px' }}
                                    />
                                </Media>
                                <Media body className="ml-2">
                                    <Link
                                        to={`/details/${favorite.itemId}`}
                                    >
                                        {favorite.title}
                                    </Link>
                                    <Typography marginBottom="0">Precio: {favorite.price}</Typography>
                                    <Button
                                        color="danger"
                                        onClick={() => handleOnDeleteFavorite(favorite.id, favorite.itemId, favorite._version)}
                                    >
                                        {state.submitting && state.selected.itemId === favorite.itemId ? 'Un momento...' : 'Remover'}
                                    </Button>
                                </Media>
                            </Media>
                        </Box>
                    )) : (
                        <>
                            <Box display="block" background="#fff" marginTop="10px" padding="10px">
                                <Typography>
                                    Nada guardado
                                </Typography>
                            </Box>
                        </>
                    )}
                </Col>
            </Row>
        </ContainerStyled>
    );
}

export default Favorites;
