import styled from 'styled-components';
import { Container } from 'reactstrap';

const ContainerStyled = styled(Container)`
    width: 500px;
    @media (max-width: 415px) {
        &.wrapper {
            width: 100% !important;
        }
    }
`;

const FormWrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 3px;

    @media (max-width: 992px) {
        width: 100%;
    }
`;

const UserInfoWrapper = styled.div`
    justify-content: center;
    display: flex;
    align-items: baseline;
`;

const Main = styled.div``;

const Image = styled.img`
    ${({ width, height, margin }) => ({
        width, height, margin
    })}
`;

export {
    Main,
    ContainerStyled,
    FormWrapper,
    Image,
    UserInfoWrapper
};
