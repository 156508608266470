/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSuapmiLocation = `query GetSuapmiLocation($id: ID!) {
  getSuapmiLocation(id: $id) {
    id
    city
    state
    street
    zipcode
    geolocation
    owner
  }
}
`;
export const listSuapmiLocations = `query ListSuapmiLocations(
  $filter: ModelSuapmiLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listSuapmiLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      city
      state
      street
      zipcode
      geolocation
      owner
    }
    nextToken
  }
}
`;
export const getWatchlist = `query GetWatchlist($id: ID!) {
  getWatchlist(id: $id) {
    id
    createdAt
    owner
    itemId
    title
    price
    image
  }
}
`;
export const listWatchlists = `query ListWatchlists(
  $filter: ModelWatchlistFilterInput
  $limit: Int
  $nextToken: String
) {
  listWatchlists(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      owner
      itemId
      title
      price
      image
    }
    nextToken
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    username
    email
    owner
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      email
      owner
    }
    nextToken
  }
}
`;
export const watchlistByDate = `query WatchlistByDate(
  $owner: String
  $createdAt: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWatchlistFilterInput
  $limit: Int
  $nextToken: String
) {
  watchlistByDate(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      owner
      itemId
      title
      price
      image
    }
    nextToken
  }
}
`;
export const getItem = `query GetItem($id: ID!) {
  getItem(id: $id) {
    id
    title
    price
    description
    location
    condition
    status
    category
    subCategory
    images
    quantity
    additionalInfo
    geolocation
    postedDate
    owner
  }
}
`;
export const listItems = `query ListItems(
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      price
      description
      location
      condition
      status
      category
      subCategory
      images
      quantity
      additionalInfo
      geolocation
      postedDate
      owner
    }
    nextToken
  }
}
`;
