import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import { APP_NAME } from 'app-exports';
import { Link } from 'react-router-dom';

const List = styled.ul`
    display: flex;
    place-content: space-evenly;
    list-style-type: none;
    @media(max-width: 411px) {
        display: initial;
    }
`;

const ListInlineItem = styled.li`
    @media(max-width: 411px) {
        padding: 10px 0;
    }
`;

const FooterWrapper = styled.footer`
    border-top: 1px solid #eee;
`;

function Footer() {
    return (
        <FooterWrapper className="footer mt-auto py-3 bg-dark text-white">
            <Container>
                <List>
                    <ListInlineItem>&#169; {APP_NAME} {new Date().getFullYear()}</ListInlineItem>
                    <ListInlineItem>
                        <Link to="/contact" className="text-light">Contactar</Link>
                    </ListInlineItem>
                    <ListInlineItem>
                        <Link to="/terms-of-service" className="text-light">Términos de Servicio</Link>
                    </ListInlineItem>
                    <ListInlineItem>
                        <Link to="/privacy-policy" className="text-light">Política de Privacidad</Link>
                    </ListInlineItem>
                </List>
            </Container>
        </FooterWrapper>
    );
}

export default Footer;
