import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
    Row, Col, Spinner, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { PageTitle } from 'components';
import { APP_LANG } from 'app-exports';
import { Typography } from 'lib';
import InfiniteScroll from 'react-infinite-scroll-component';
import LANGUAGES from 'languages.json';
import get from 'lodash.get';
import queryString from 'query-string';
import isEmpty from 'lodash.isempty';
import NewItem from 'components/Item';
import Masonry from 'react-masonry-css';
import { getCategoryLabel, transformESResult } from 'utils';
import schemaClassified from 'schemas/schema.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Filters from './Filters';

const DataGridStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    margin-bottom: 20px;

    @media(max-width: 411px) {
        padding: 0;
    }
`;

const InfiniteScrollWrapper = styled.div`
    @media(min-width: 360px) {
        width: 100%;
    }
`;

function DataGrid(props) {
    const {
        data,
        hasMore,
        location,
        params,
        isLoading
    } = props;
    const [state, setState] = useState({ isOpen: false, openDrop: false });
    const urlQueryParams = queryString.parse(location.search);
    const category = getCategoryLabel(urlQueryParams.category, schemaClassified.categories);
    // handlers
    const setQuery = get(props, 'handlers.setQuery');

    function getLoader() {
        return <div className="text-center"><Spinner size="sm" color="secondary" /> {LANGUAGES[APP_LANG].LOADING}</div>;
    }

    function getLatestItemsForSale() {
        const parsedQuery = queryString.stringify({
            ...params,
            ...urlQueryParams,
            page: parseInt(params.page, 10) + 1
        });
        setQuery(parsedQuery);
    }

    function getActions(item) {
        const { handlers } = props;

        return {
            onViewDetails: () => handlers.onViewDetails(item)
        };
    }

    function toggle() {
        setState((prevState) => ({ ...prevState, isOpen: !state.isOpen }));
    }

    function getSortText() {
        // let sort;
        // switch (urlQueryParams.sort) {
        // case 'i.title desc':
        //     sort = 'Name DESC (Z-A)';
        //     break;
        // case 'i.title asc':
        //     sort = 'Name ASC (A-Z)';
        //     break;
        // case 'i.price asc':
        //     sort = 'Price lowest to highest';
        //     break;
        // case 'i.price desc':
        //     sort = 'Price highest to lowest';
        //     break;
        // default:
        //     sort = 'Filters';
        // }
        // return sort;
        return null;
    }

    function onItemClicked(selected) {
        const { handlers: { handleSetQuery } } = props;
        handleSetQuery({ subCategory: selected.value });
    }

    function getPageTitle() {
        const subCategoriesList = get(schemaClassified, `subCategories.${urlQueryParams.category}`, []);
        const subCategory = subCategoriesList.find((c) => c.value === urlQueryParams.subCategory);
        const hasCategories = subCategoriesList.length === 0;
        return (
            <>
                {isMobile ? (
                    <ButtonDropdown
                        disabled={hasCategories}
                        isOpen={state.openDrop}
                        toggle={
                            () => setState((prevState) => ({ ...prevState, openDrop: !state.openDrop }))
                        }
                    >
                        <DropdownToggle outline caret={!hasCategories} color="link" className="pl-0">
                            {category.label} {subCategory && ` / ${subCategory.label}`}
                        </DropdownToggle>
                        <DropdownMenu>
                            {subCategoriesList.map((subcategory) => (
                                <DropdownItem key={subcategory.key} onClick={() => onItemClicked(subcategory)}>
                                    {subcategory.label}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </ButtonDropdown>
                ) : (
                    <div>
                        <Typography
                            color="#544c4c"
                        >
                            {category.label} {subCategory && (
                                <>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        style={{ fontSize: '10px', margin: '0 5px' }}
                                    />{' '}{subCategory.label}
                                </>
                            )}
                        </Typography>
                    </div>
                )}
            </>
        );
    }

    function renderContent() {
        if (isEmpty(data) && !isLoading) {
            return (
                <div>
                    <strong>No contenido</strong>
                    <Typography type="a" href="/" marginLeft="5px">
                        Ver Mas Reciente
                    </Typography>
                </div>
            );
        }
        if (isLoading && !hasMore) {
            return (
                <div>
                    <Spinner size="sm" color="secondary" /> {LANGUAGES[APP_LANG].LOADING}
                </div>
            );
        }
        return (
            <InfiniteScrollWrapper className="infinite-scroll-wrapper">
                <InfiniteScroll
                    hasMore={hasMore}
                    next={getLatestItemsForSale}
                    dataLength={data.length}
                    loader={getLoader()}
                >
                    <Masonry
                        breakpointCols={{
                            default: 5,
                            1100   : 5,
                            700    : 3,
                            500    : 2
                        }}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {data.map((item) => (
                            <NewItem
                                key={item.id}
                                data={transformESResult(item)}
                                isMobile={isMobile}
                                actions={getActions(item)}
                                className="mb-3"
                            />
                        ))}
                    </Masonry>
                </InfiniteScroll>
            </InfiniteScrollWrapper>
        );
    }

    return (
        <>
            <Row form>
                <Col md="4" lg="3" className={isMobile ? 'mb-1' : ''}>
                    <Filters {...props} />
                </Col>
                <Col md="8" sm="12" lg="9">
                    <DataGridStyled>
                        <PageTitle
                            title={getPageTitle()}
                            rightContent={getSortText()}
                            margin="10px 0"
                            open={toggle}
                        />
                        {renderContent()}
                    </DataGridStyled>
                </Col>
            </Row>
            {/* NOTE: The filters need to be in sync with elasticsearch */}
            {/* <FiltersModal
                {...props}
                toggle={toggle}
                handleOnChange={(selected) => props.handlers.handleSetQuery({ sort: selected, page: 1 })}
                // handleOnSubmit={props.handlers.handleResetFilter}
                isOpen={state.isOpen}
                category={urlQueryParams.category}
                options={get(props, 'schema.sortBy.options', [])}
            /> */}
        </>
    );
}

DataGrid.propTypes = {
    data        : PropTypes.array,
    badgeText   : PropTypes.string,
    hasMore     : PropTypes.bool,
    totalRecords: PropTypes.number,
    handlers    : PropTypes.object,
    location    : PropTypes.object,
    params      : PropTypes.object,
    isLoading   : PropTypes.bool
};

DataGrid.defaultProps = {
    data        : [],
    badgeText   : null,
    hasMore     : true,
    totalRecords: 0,
    handlers    : {},
    location    : {},
    params      : {},
    isLoading   : false
};

export default DataGrid;
