const isProduction = process.env.REACT_APP_ENV === 'production';
const elasticSearch = {
    searchKey   : isProduction ? process.env.REACT_APP_ELASTIC_SEARCH_KEY : 'search-7juuh4bh77kkeieagw6axsz7',
    engineName  : isProduction ? 'suapmi-search-engine-production' : 'suapmi-search-engine-staging',
    endpointBase: isProduction
        ? 'https://suapmi-search-deployment-production.ent.eastus2.azure.elastic-cloud.com'
        : 'https://suapmi-search-engine-staging.ent.us-west-1.aws.found.io',
    cacheResponses: false
};

const APP_LANG = 'sp';

/**
 * App name. Leave empty if you want to use the log
 */
const APP_NAME = 'Suapmi Inc.';
const TAG_LINE = 'BETA';

/**
 * API key to access google services
 */
const GOOGLE_MAP_ACCESS_KEY = 'AIzaSyD6lcKSbUec7m71-VVYqNQIyK4n7ztB0c4';

/**
 * App logo
 */
const APP_LOGO_URL = 'https://s3-us-west-2.amazonaws.com/suapmi-storage/suapmi-logo.png';

/**
 * Elasticsearch endpoint
 */
const ELASTICSEARCH_ENDPOINT = window.location.hostname === 'localhost'
    ? 'http://elasticsearch.suapmi-local.com/doc'
    : 'https://elasticsearch.suapmi.com/doc';

/**
 * No image
 */
const NO_IMG_URL = 'https://suapmi-storage.s3-us-west-2.amazonaws.com/no-image-icon-15.png';

const S3_BUCKET = process.env.REACT_APP_ENV === 'production'
    ? 'suapmi464a166ad903470ea189b4d8d3cca5c2220358-production'
    : 'suapmi464a166ad903470ea189b4d8d3cca5c201117-staging';

const CLOUDFRONT_DISTRIBUTION = process.env.REACT_APP_ENV === 'production'
    ? 'https://d2r9yl5x2bwn7.cloudfront.net'
    : 'https://d3oje20txgmcq8.cloudfront.net';

const STATUS = {
    ACTIVE  : 1,
    SOLD    : 2,
    ARCHIVED: 3
};

const STATUS_LABEL = {
    1: { LABEL: 'En venta' },
    2: { LABEL: 'Vendido' },
    3: { LABEL: 'Archivado' }
};

export {
    APP_LANG,
    APP_LOGO_URL,
    APP_NAME,
    CLOUDFRONT_DISTRIBUTION,
    TAG_LINE,
    ELASTICSEARCH_ENDPOINT,
    GOOGLE_MAP_ACCESS_KEY,
    NO_IMG_URL,
    STATUS_LABEL,
    S3_BUCKET,
    elasticSearch,
    STATUS
};
