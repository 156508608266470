import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Component = styled.div`
    ${({
        as, children, textAlign, fontSize, width, margin, truncate, ...rest
    }) => {
        let trun;
        if (truncate) {
            trun = {
                whiteSpace  : 'nowrap',
                overflow    : 'hidden',
                textOverflow: 'ellipsis'
            };
        }
        return {
            textAlign,
            fontSize,
            width,
            margin,
            ...trun,
            ...rest
        };
    }}
`;

function Typography(props) {
    const {
        // eslint-disable-next-line react/prop-types
        children, className, type, ...rest
    } = props;
    return (
        <Component as={type} {...rest}>
            {children}
        </Component>
    );
}

Typography.propTypes = {
    children: PropTypes.any,
    type    : PropTypes.oneOf(['a', 'p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
};

Typography.defaultProps = {
    children: null,
    type    : 'p'
};

export default Typography;
