/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const notifyElasticSearch = `mutation NotifyElasticSearch($input: ElasticSearchItemInput) {
  notifyElasticSearch(input: $input)
}
`;
export const createSuapmiLocation = `mutation CreateSuapmiLocation(
  $input: CreateSuapmiLocationInput!
  $condition: ModelSuapmiLocationConditionInput
) {
  createSuapmiLocation(input: $input, condition: $condition) {
    id
    city
    state
    street
    zipcode
    geolocation
    owner
  }
}
`;
export const updateSuapmiLocation = `mutation UpdateSuapmiLocation(
  $input: UpdateSuapmiLocationInput!
  $condition: ModelSuapmiLocationConditionInput
) {
  updateSuapmiLocation(input: $input, condition: $condition) {
    id
    city
    state
    street
    zipcode
    geolocation
    owner
  }
}
`;
export const deleteSuapmiLocation = `mutation DeleteSuapmiLocation(
  $input: DeleteSuapmiLocationInput!
  $condition: ModelSuapmiLocationConditionInput
) {
  deleteSuapmiLocation(input: $input, condition: $condition) {
    id
    city
    state
    street
    zipcode
    geolocation
    owner
  }
}
`;
export const createItem = `mutation CreateItem(
  $input: CreateItemInput!
  $condition: ModelItemConditionInput
) {
  createItem(input: $input, condition: $condition) {
    id
    title
    price
    description
    location
    condition
    status
    category
    subCategory
    images
    quantity
    additionalInfo
    geolocation
    postedDate
    owner
  }
}
`;
export const updateItem = `mutation UpdateItem(
  $input: UpdateItemInput!
  $condition: ModelItemConditionInput
) {
  updateItem(input: $input, condition: $condition) {
    id
    title
    price
    description
    location
    condition
    status
    category
    subCategory
    images
    quantity
    additionalInfo
    geolocation
    postedDate
    owner
  }
}
`;
export const deleteItem = `mutation DeleteItem(
  $input: DeleteItemInput!
  $condition: ModelItemConditionInput
) {
  deleteItem(input: $input, condition: $condition) {
    id
    title
    price
    description
    location
    condition
    status
    category
    subCategory
    images
    quantity
    additionalInfo
    geolocation
    postedDate
    owner
  }
}
`;
export const createWatchlist = `mutation CreateWatchlist(
  $input: CreateWatchlistInput!
  $condition: ModelWatchlistConditionInput
) {
  createWatchlist(input: $input, condition: $condition) {
    id
    createdAt
    owner
    itemId
    title
    price
    image
  }
}
`;
export const updateWatchlist = `mutation UpdateWatchlist(
  $input: UpdateWatchlistInput!
  $condition: ModelWatchlistConditionInput
) {
  updateWatchlist(input: $input, condition: $condition) {
    id
    createdAt
    owner
    itemId
    title
    price
    image
  }
}
`;
export const deleteWatchlist = `mutation DeleteWatchlist(
  $input: DeleteWatchlistInput!
  $condition: ModelWatchlistConditionInput
) {
  deleteWatchlist(input: $input, condition: $condition) {
    id
    createdAt
    owner
    itemId
    title
    price
    image
  }
}
`;
export const createUser = `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    username
    email
    owner
  }
}
`;
export const updateUser = `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    username
    email
    owner
  }
}
`;
export const deleteUser = `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    username
    email
    owner
  }
}
`;
