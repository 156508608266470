/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from 'lib';
import { formatCurrensy, renderImageFromCloudFront } from 'utils';
import { Image } from 'common';
import { NO_IMG_URL } from 'app-exports';

const CardWrapper = styled.div`
    :hover {
        cursor: pointer;
    }
`;

function NewItem(props) {
    const {
        data, actions, className, imgSize
    } = props;
    const {
        title, image, location, price
    } = data;

    function renderImage() {
        if (!image) { return null; }
        return image
            ? <Image src={renderImageFromCloudFront(image, imgSize)} />
            : <Image src={NO_IMG_URL} style={{ height: '130px', objectFit: 'contain' }} />;
    }

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <CardWrapper className={`card ${className}`} onClick={actions.onViewDetails}>
            {renderImage()}
            <div className="card-body p-2">
                <Typography type="p" marginBottom="0" fontWeight="600" truncate>{title}</Typography>
                <Typography type="p" margin="5px 0">{formatCurrensy(price)}</Typography>
                {location && <Typography color="#4C4848" margin="3px 0 0 0" textTransform="capitalize">{location}</Typography>}
            </div>
        </CardWrapper>
    );
}

NewItem.propTypes = {
    data     : PropTypes.object,
    actions  : PropTypes.object,
    className: PropTypes.string,
    imgSize  : PropTypes.number
};

NewItem.defaultProps = {
    data     : {},
    actions  : () => {},
    className: '',
    imgSize  : 180
};

export default NewItem;
