/* eslint-disable no-console */
// eslint-disable-next-line no-unused-vars
import React, { createContext } from 'react';

export const AppContext = createContext({});

export const reducer = (state, action) => {
    const { data, type } = action;
    switch (type) {
    case 'GET_APPLICATION_DATA_START':
        return {
            ...state,
            status: 'FETCHING'
        };
    case 'GET_APPLICATION_DATA_SUCCESS':
        return {
            ...state,
            user   : data.user,
            address: data.address,
            status : 'FETCHING_DONE'
        };
    case 'GET_ITEMS_SUCCESS':
        return {
            ...state,
            items: {
                forSale     : state.items.forSale.concat(data.forSale),
                totalRecords: data.totalRecords,
                currentPage : data.currentPage
            }
        };
    case 'GET_USER_NOT_FOUND_INIT':
        return { ...state };
    case 'GET_USER_SUCCESS_INIT':
    case 'USER_LOGIN_SUCCESS':
        return {
            ...state,
            user: data
        };
    case 'USER_LOGOUT_SUCCESS':
        return {
            ...state,
            user: data
        };
    case 'USER_REGISTRATION_INIT':
        return {
            ...state,
            status: 'FETCHING_DONE'
        };
    case 'USER_REGISTRATION_SUCCESS':
        return {
            ...state,
            user: data
        };

    case 'USER_LOCATION_SUCCESS':
        return {
            ...state,
            address: data,
            status : 'FETCHING_DONE'
        };

    case 'RESET_USER':
        console.log('RESET_USER', data);
        return {
            ...state,
            user     : data.user,
            authState: null
        };

    case 'AUTHENTICATION_STATE':
        return {
            ...state,
            authState: data
        };
    default:
        return state;
    }
};

export default AppContext;
