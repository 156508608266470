/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { Suspense, lazy } from 'react';
import styled from 'styled-components';
import { API, Auth } from 'aws-amplify';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Header } from 'components';
import { ToastContainer } from 'react-toastify';
import { AppContext, reducer } from 'appContext';
import { getUserLocation } from 'utils';
import { Container, Spinner } from 'reactstrap';
import * as queries from 'graphql/queries';
import Admin from 'pages/Admin';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const Home = lazy(() => import('./pages/Home'));
const Details = lazy(() => import('./pages/Details'));
const Private = lazy(() => import('./pages/Private'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Search = lazy(() => import('./pages/Search'));
const Create = lazy(() => import('./pages/Create'));
const Classifieds = lazy(() => import('./pages/Classifieds'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const Verify = lazy(() => import('./pages/Verify'));
const Main = styled.main`
    width: 100%;
    position: absolute;
    margin-top: 80px;
`;

const appState = {
    status      : 'FETCHING',
    user        : null,
    address     : null,
    subscription: null,
    items       : {
        forSale     : [],
        totalRecords: 0,
        currentPage : 1
    }
};

async function loadApplicationData(dispatch) {
    dispatch({ type: 'GET_APPLICATION_DATA_START' });

    let user = null;

    try {
        user = await Auth.currentAuthenticatedUser({ bypassCache: false });
    } catch (err) {
        console.log('Error getting user', err);
    }

    let userLocation = null;
    try {
        const resp = await getUserLocation(API, queries);
        userLocation = Array.isArray(resp) ? resp[0] : null;
    } catch (err) {
        console.log('Error getting user location', err);
    }
    // eslint-disable-next-line no-debugger
    let currentUser = null;
    if (user) {
        const { attributes, username } = user;
        currentUser = { ...attributes, username };
    }

    dispatch({
        type: 'GET_APPLICATION_DATA_SUCCESS',
        data: {
            user   : currentUser,
            address: userLocation
        }
    });
}

function Routing() {
    const [state, dispatch] = React.useReducer(reducer, appState);
    React.useEffect(() => {
        loadApplicationData(dispatch);
    }, []);

    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <h1>Thank you for visiting Suapmi.com</h1>
        </div>
        // <AppContext.Provider value={[state, dispatch]}>
        //     <Header className="header" />
        //     {state.status !== 'FETCHING_DONE' ? (
        //         <Container className="container-details mb-3">
        //             <div className="pt-3 pl-3 text-center">
        //                 <Spinner size="sm" color="secondary" /> Un momento...
        //             </div>
        //         </Container>
        //     ) : (
        //         <Main role="main">
        //             <Suspense fallback="Loading...">
        //                 <Switch>
        //                     <Route exact path="/" component={Home} />
        //                     <Route path="/classifieds" component={Classifieds} />
        //                     <Route path="/search" component={Search} />
        //                     <Route path="/details/:itemId" component={Details} />
        //                     <Route path="/private" component={Private} />
        //                     <Route path="/privacy-policy" component={PrivacyPolicy} />
        //                     <Route path="/terms-of-service" component={TermsOfService} />
        //                     <Route path="/item/:action" component={Create} />
        //                     <Route path="/admin" component={Admin} />
        //                     <Route path="/verify" component={Verify} />
        //                     <Route path="/contact" component={ContactUs} />
        //                 </Switch>
        //             </Suspense>
        //         </Main>
        //     )}
        // </AppContext.Provider>
    );
}

function App() {
    return (
        <BrowserRouter>
            <Routing />
            <ToastContainer
                hideProgressBar
                pauseOnFocusLoss={false}
                className="toast-container"
            />
        </BrowserRouter>
    );
}

export default App;
